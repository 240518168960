import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import BreadcrumbBack from '../../../../components/breadcrumb/BreadcrumbBack';
import Input from '../../../../components/form/Input';
import formValidationRules from '../../../../helpers/formvalidationRules';
import FormButton from '../../../../components/button/FormButton';
import RadioButton from '../../../../components/form/RadioButton';
import { statusList, toBoolean } from '../../../../helpers/commonHelpers';
import SearchableSelect from '../../../../components/form/SearchableSelect';
import { createImageCategory, editImageCategory, showImageCategory } from '../../../../service/imageCategoet.service';
import { useDispatch } from 'react-redux';
import { setContentUpdate } from '../../../../redux/contentUpdateSlice';

export default function AdminImageCategoryCE(props: {
    type: string;
    data?: any;
    open: any;
  }) {
    const [pageLoad, setPageLoad]= useState(false);
    const [fetchData, setFetchData] =useState<any>();
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false);
    const [pageContent, setPageContent] = useState({
        button:"Create Category",
    })
    let location = useLocation();
    const dispatch = useDispatch();
    const fetchBatchDetail = async(id:string)=>{
        const get:any = await showImageCategory(id)
      if(get?.status === "success"){
        setFetchData(get.message)
      }
    }
    useEffect(()=>{ 

        setPageLoad(true)
        if(props.type === "edit"){
          fetchBatchDetail(props.data.id)
            setPageContent({
                button:"Edit Category"
            })
        }
      
        setPageLoad(false)
      },[])


      const { handleSubmit, control } = useForm<{name:string,status:any}>({
        values:{
            name:fetchData?.name,
            status:fetchData?.status ? fetchData?.status === true ? "true" : "false" : null,
        }
      });
      const onSubmit: SubmitHandler<{name:string,status:any}> = async(data:{name:string,status:any}) => {
        setLoading(true);
        let fetch:any;
       if(props.type==="create"){
        fetch = await createImageCategory(data.name, toBoolean(data.status.value))
       }
       if(props.type === "edit"){
        fetch = await editImageCategory(fetchData?.id,data.name, toBoolean(data.status.value));
       }
       if(fetch?.status=== "success"){
        setLoading(false)
        dispatch(
          setContentUpdate({
            id: Math.random() * (100000 - 111111),
          })
        );
        props.open(false)
        return toast.success(fetch.message);
      }
      setLoading(false)
      
      };  
  return (
    <>
    <div className=" rounded-xl ">
    <form onSubmit={handleSubmit(onSubmit)} className='mb-5'>
   
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-12">
        <Input 
       name="name"
       control={control}
       rules={{
        ...formValidationRules.required(),
        ...formValidationRules.minLength(3)
       }}
       label="Full Name"
       placeholder="full name"
      />
        </div>
      <div className="col-span-12">
      <SearchableSelect
         name="status"
         control={control}
         options={statusList}
         rules={formValidationRules.required()}
         label="Status"
         placeholder="Search.."
        />
      </div>
      </div>
       <div className="mt-3">
       <FormButton loading={loading} title={pageContent.button} />
       </div>
      </form>
    </div>
    </>
  )
}


