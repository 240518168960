import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { adminImagesType } from '../../../types/AdminType';
import { toast } from 'react-toastify';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import Input from '../../../components/form/Input';
import formValidationRules from '../../../helpers/formvalidationRules';
import FormButton from '../../../components/button/FormButton';
import { getActiveImageCategory } from '../../../service/imageCategoet.service';
import SearchableSelect from '../../../components/form/SearchableSelect';
import { statusList, toBoolean } from '../../../helpers/commonHelpers';
import DropZoneUploadImages from '../../../components/dropzone/DropZoneUploadImages';
import WordUpload from '../../../components/uploads/WordUpload';
import { createImage, editImage, showImage } from '../../../service/images.service';

export default function AdminImageCE() {
    const [pageLoad, setPageLoad]= useState(false);
    const [fetchData, setFetchData] =useState<any>();
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [url, setUrl] = useState<any>();
    const [checkUploadedFilesStatus, setCheckUploadedFilesStatus] = useState<any>(false);
    const [pageContent, setPageContent] = useState({
        button:"upload Image",
    })

    const [objectURL, setObjectURL] = useState<any>(null)
    let location = useLocation();
    let pageType = location.pathname.split('/')[location.pathname.split('/').length-1];
    let dataid = location.pathname.split('/')[location.pathname.split('/').length-2];

    let navigate = useNavigate()
    const fetchCategories = async()=>{
     let get:any = await getActiveImageCategory()
     if(get?.status === "success"){
      let newData:any = [];
      if(get?.message.length>0){
        get?.message.map((e:any,i:number)=>{
          newData.push({
            label:e.name,
            value:e.id
          })
        })
      }
      setCategories(newData)
     }
    }
    const imageDetail = async(id:string)=>{
        const get:any = await showImage(id)
      if(get?.status === "success"){
        setFetchData(get.message)
        setUrl(get.message?.image_url)
      }
    }
    useEffect(()=>{
        fetchCategories()
        setPageLoad(true)
        if(pageType === "edit"){
          imageDetail(dataid)
            setPageContent({
                button:"Edit Image"
            })
        }

        setPageLoad(false)
      },[])


      const { handleSubmit, control } = useForm<adminImagesType>({
        values:{
            fk_category_id:fetchData?.fk_category_id,
            name:fetchData?.name ,
            description:fetchData?.description ,
            keywords:fetchData?.keywords ,
            amount:fetchData?.amount ? fetchData?.amount : 0,
            discount:fetchData?.discount ? fetchData?.discount : 0,
            status: fetchData?.status === true ? "true" : "false" ,
            url:fetchData?.url,
            object_url:fetchData?.object_url
        }
      });
      const onSubmit: SubmitHandler<adminImagesType> = async(data:adminImagesType) => {
        setLoading(true);
        console.log(url, objectURL)
        if(url === null){
            setLoading(false)
            return toast.error("Please select Image")
        }
        if(objectURL === null){
            setLoading(false)
            return toast.error("Please select file data or object")
        }
        data.object_url = objectURL;
        data.url = url
        data.fk_category_id = data.fk_category_id.value
        data.status = fetchData?.status ? fetchData?.status : toBoolean(data.status)
        let fetch:any;
        console.log(data)
       if(pageType==="create"){
        fetch = await createImage(data);
       }
       if(pageType === "edit"){
        data.fk_category_id = fetchData?.fk_category_id ? fetchData?.fk_category_id : data.fk_category_id.value
        fetch = await editImage(fetchData?.id,data);
       }
       if(fetch?.status=== "success"){
        setLoading(false)
        navigate("/admin/images")
        return toast.success(fetch.message);
      }
      setLoading(false)

      };
     const  handleObjectSubmit = (e:any)=>{
        setObjectURL(e)
      }
  return (
    <>
    <BreadcrumbBack title={pageContent.button} >
    </BreadcrumbBack>

    <div className="py-3 md:py-8 px-1 md:px-3 bg-gray-300 dark:bg-[#0b0b0b2d] p-3 rounded-xl my-5">
    <form onSubmit={handleSubmit(onSubmit)} className='mb-5'>

      <div className="grid grid-cols-12 gap-3">
      <div className="col-span-12 sm:col-span-12 md:col-span-6 ">
        <Input
       name="name"
       control={control}
       rules={{
        ...formValidationRules.required(),
        ...formValidationRules.minLength(3)
       }}
       label="Title"
       placeholder="title"
      />
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-6">
        <SearchableSelect
         name="fk_category_id"
         control={control}
         options={categories}
         rules={formValidationRules.required()}
         label="Select Category"
         placeholder="Search.."
        />
        </div>

        <div className="col-span-12 sm:col-span-12 md:col-span-6">
        <Input
       name="description"
       control={control}
       label="Description"
       placeholder="description"
      />
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-6">
        <Input
       name="keywords"
       control={control}
       label="Keywords"
       placeholder="keywords"
      />
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-6">
        <Input
       name="amount"
       type='number'
       control={control}
       label="Amount"
       placeholder="amount"
      />
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-6">
        <Input
       name="discount"
       type='number'
       control={control}
       label="Discount"
       placeholder="discount"
      />
        </div>
        <div className="col-span-12 my-2">
      <DropZoneUploadImages heigth='min-h-[25vh]' title="Upload Thumbnail" isRequired={true} maxFile={1} data={setUrl} url={url} status={setCheckUploadedFilesStatus}  />

        </div>
        <div className="col-span-12">
        <label  className={` ml-1  text-gray-600 dark:text-gray-400`}>
        Upload File  <sup className="text-red-500">*</sup>
      </label>
      {
        fetchData?.object_url ?
            <div className='flex justify-start pr-[2rem] gap-2'>
                <a href={fetchData?.object_url} target='_blank' className='bg-purple2 text-white px-3 py-2 rounded-lg'>View / Download</a>
                <button onClick={(()=>setFetchData((pre:any)=>({...pre,object_url:null})))} className='bg-red-500 text-white px-4 py-2 rounded-lg '>Change</button>
            </div>

        :
        <>

      <WordUpload name="adminImageObject" data={handleObjectSubmit} />
        </>
      }
        </div>
        <div className="col-span-12">
        <SearchableSelect
         name="status"
         control={control}
         options={statusList}
         rules={formValidationRules.required()}
         label="Status"
         placeholder="Search.."
        />
        </div>
      </div>
       <div className="mt-3">
       <FormButton loading={loading} title={pageContent.button} />
       </div>
      </form>
    </div>
    </>
  )
}


